



















import { defineComponent, SetupContext } from '@vue/composition-api'

import { CustomParagraph } from '../Paragraph'

import CloseIcon from './partials/CloseIcon.vue'
import { ModalInnerProps } from './ModalInner.contracts'
import { modalInnerProps, useModalInner } from './ModalInner.hooks'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ModalInner = defineComponent({
  name: 'ModalInner',
  props: modalInnerProps,
  components: { CloseIcon, CustomParagraph },
  setup (props: ModalInnerProps, ctx: SetupContext) {
    return useModalInner(props, ctx.emit)
  }
})
export default ModalInner
