














import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<TagWrapper>({
  name: 'TagWrapper',
  created () {
    this.first = this.steps[0]
  }
})
export class TagWrapper extends Vue {
  public first: string = ''

  @Prop({ type: Array, required: true })
  public readonly steps!: string[]

  public get isWrapper (): boolean {
    return this.steps.length > 0
  }

  public get thisStep (): string {
    const el = this.steps.shift()

    if (typeof el === 'undefined') {
      return this.steps[0]
    }

    return el
  }
}

export default TagWrapper
