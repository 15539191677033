








import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { TagWrapper } from '../../abstract/components/TagWrapper/TagWrapper.vue'

import { customParagraphProps, useCustomParagraph } from './Paragraph.hooks'
import { ParagraphProps } from './Paragraph.contracts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 *
 */
export const Paragraph = defineComponent({
  name: 'Paragraph',
  components: { TagWrapper },
  props: customParagraphProps,

  setup (props: ParagraphProps) {
    return useCustomParagraph(props, getCurrentInstance())
  }
})
export default Paragraph
